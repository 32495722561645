<template>
  <div class="container">
    <h1>Lista de Planos Estratégicos</h1>
    <div class="card">
      <div class="card-body">
        <b-table
          v-if="$can('strategic_plan:view')"
          striped
          hover
          :items="strategic_plans"
          :fields="fields"
        >
          <template #cell(start_term)="row">
            {{ row.item.start_term | format_date }}
          </template>
          <template #cell(end_term)="row">
            {{ row.item.end_term | format_date }}
          </template>
          <template #cell(actions)="row">
            <Actions
              :item="row.item"
              :view_show="$can('strategic_plan:view')"
              :view_edit="$can('strategic_plan:edit')"
              :view_delete="$can('strategic_plan:delete')"
              view_to="strategic_plans_show"
              edit_to="strategic_plans_edit"
              @delete="delete_strategic_plan"
            />
          </template>
        </b-table>
      </div>
    </div>
    <div class="overflow-auto mt-4">
      <b-pagination-nav
        v-model="page"
        :link-gen="linkGen"
        :number-of-pages="pagination.last_page"
        use-router
      ></b-pagination-nav>
    </div>
  </div>
</template>

<script>
import Actions from "@/components/Table/Actions.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: { Actions },
  data() {
    return {
      page: 1
    };
  },
  computed: {
    ...mapState("strategic_plans", ["strategic_plans", "pagination"]),
    fields() {
      return [
        { key: "id", label: "#" },
        { key: "name", label: "Nome" },
        { key: "start_term", label: "Início" },
        { key: "end_term", label: "Fim" },
        { key: "responsible.name", label: "Responsável" },
        { key: "organizationalStructure.name", label: "Setor" },
        { key: "actions", label: "#" }
      ];
    }
  },
  methods: {
    ...mapActions("strategic_plans", ["ActionGetAll", "ActionDelete"]),
    async delete_strategic_plan(strategic_plan) {
      try {
        await this.ActionDelete(strategic_plan);
        this.$notifications.addNotification({
          message: "Registro deletado com sucesso",
          type: "success"
        });
        await this.ActionGetAll({ page: this.page });
      } catch (error) {
        this.$notifications.addNotification({
          message: "Erro ao excluir registro",
          type: "danger"
        });
      }
    },
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    }
  },
  async created() {
    if (this.$route.query.page > 1) {
      this.page = this.$route.query.page;
    }
    await this.ActionGetAll({ page: this.page });
  }
};
</script>

<style></style>
